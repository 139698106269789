import {Component} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public version = version.version

  constructor(
    public configService: ConfigService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Add custom SVG icons to use later in <mat-icon>
    this.matIconRegistry.addSvgIcon(
      'manually_issued',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/manually_issued.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'add_notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/add_notes.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'edit_notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/edit_notes.svg')
    )
  }
}
